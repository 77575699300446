<template>
    <div class="single-vid-wrapper" v-if="mainVideoType && mainVideoType == 'Masterclass'">
        <HeroVideoSingle :shortData="shortData" :data="data" :trainerData="trainerData"
            :videoAssetData="mediaDetailsObj" @playMainVid="playMainVid" @playTrailerVid="playTrailerVid" />
        <section class="vid-single-meta">
            <div class="container">
                <div v-if="data" class="column-wrapper half-half">
                    <div class="column desc" v-if="data.content">
                        <span v-if="displayNewLabel" class="global-label new">
                            New
                        </span>
                        <h2 class="column-title">Masterclass info</h2>
                        <div v-html="data.content"></div>

                        <!-- <a v-if="data.fields.url && data.fields.url !== ''" target="_blank" class="button extra-btn"
                            :href="data.fields.url">{{ data.fields.label }}</a> -->

                        <div class="download-instructions" v-if="checkIfHasDownloads()">
                            <h3 class="download-title">Download instructions</h3>
                            <ul>
                                <li v-if="data.fields.nlinstructions && data.fields.nlinstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.nlinstructions"
                                        target="_blank">NL</a></li>
                                <li v-if="data.fields.eninstructions && data.fields.eninstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.eninstructions"
                                        target="_blank">EN</a></li>
                                <li v-if="data.fields.frinstructions && data.fields.frinstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.frinstructions"
                                        target="_blank">FR</a></li>
                                <li v-if="data.fields.esinstructions && data.fields.esinstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.esinstructions"
                                        target="_blank">ES</a></li>
                                <li v-if="data.fields.deinstructions && data.fields.deinstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.deinstructions"
                                        target="_blank">DU</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="column meta-wrapper">
                        <!-- <div class="inner-col" v-if="data.fields">
                            <ul class="labels">
                                <li v-if="data.fields.skills &&
                                    data.fields.skills.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.skills" :key="item.value">
                                        <CatAsIcon :catValue="getEnumVal(item.value)" type="skill" />

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>
                                    </div>
                                </li>

                                <li class="duration"
                                    v-if="mediaDetailsObj && mediaDetailsObj.msDuration && mediaDetailsObj.msDuration !== ''">
                                    <div class="item-wrapper">
                                        <div class="icon-wrapper">
                                            <img src="@/assets/svgs/duration-blue.svg" class="duration-icon" />
                                        </div>
                                        <span class="label-wrapper">{{ formatToClockDuration(mediaDetailsObj.msDuration)
                                        }}</span>
                                    </div>
                                </li>

                                <li v-if="data.fields.level &&
                                    data.fields.level.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.level" :key="item.value">
                                        <CatAsIcon :catValue="getEnumVal(item.value)" type="level" />

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>

                                    </div>
                                </li>

                                <li class="trainer-wrapper" v-if="data.fields && data.fields.coachestrainers &&
                                    data.fields.coachestrainers.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.coachestrainers"
                                        :key="item.value">
                                        <div class="icon-wrapper">
                                            <img src="@/assets/svgs/trainer.svg" class="duration-icon" />
                                        </div>
                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>

                                    </div>
                                </li>
                            </ul>
                        </div> -->
                        <div class="inner-col trainer" v-if="trainerData && trainerData !== ''">
                            <router-link :to="'/coaches-trainers/' + trainerData.slug">
                                <div class="left-side"
                                    v-if="trainerData.templateContentMedia && trainerData.templateContentMedia.overviewImage && trainerData.templateContentMedia.overviewImage.url">
                                    <div class="bg-wrapper" v-bind:style="{
        'background-image':
            'url(' + trainerData.templateContentMedia.overviewImage.url + ')',
    }"></div>
                                </div>
                                <div class="right-side"
                                    v-if="trainerData.templateContentFields && trainerData.templateContentFields.displayTitle">

                                    <div class="label">Trainer / coach</div>
                                    <div class="value">{{ trainerData.templateContentFields.displayTitle }}</div>

                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="single-vid-wrapper" v-else-if="!mainVideoType || mainVideoType && mainVideoType !== 'Masterclass'">
        <HeroVideoSingleContained :shortData="shortData" :data="data" :videoAssetData="mediaDetailsObj" />
        <section class="vid-single-meta regular">
            <div class="container">
                <div v-if="data" class="column-wrapper half-half">
                    <div class="column desc" v-if="data.content">
                        <span v-if="displayNewLabel" class="global-label new">
                            New
                        </span>
                        <h2 class="column-title">Practice info</h2>
                        <div v-html="data.content"></div>

                        <!-- <a v-if="data.fields.url && data.fields.url !== ''" target="_blank" class="button extra-btn"
                            :href="data.fields.url">{{ data.fields.label }}</a> -->

                        <div class="download-instructions" v-if="checkIfHasDownloads()">
                            <h3 class="download-title">Download instructions</h3>
                            <ul>
                                <li v-if="data.fields.nlinstructions && data.fields.nlinstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.nlinstructions"
                                        target="_blank">NL</a></li>
                                <li v-if="data.fields.eninstructions && data.fields.eninstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.eninstructions"
                                        target="_blank">EN</a></li>
                                <li v-if="data.fields.frinstructions && data.fields.frinstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.frinstructions"
                                        target="_blank">FR</a></li>
                                <li v-if="data.fields.esinstructions && data.fields.esinstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.esinstructions"
                                        target="_blank">ES</a></li>
                                <li v-if="data.fields.deinstructions && data.fields.deinstructions !== ''"><a
                                        class="button download-btn" :href="data.fields.deinstructions"
                                        target="_blank">DU</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="column meta-wrapper">
                        <!-- EQUIPMENT HERE -->
                        <!-- <div class="inner-col" v-if="data.fields">
                            <ul class="labels">
                                <li v-if="data.fields.skills &&
                                    data.fields.skills.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.skills" :key="item.value">
                                        <CatAsIcon :catValue="getEnumVal(item.value)" type="skill" />

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>
                                    </div>
                                </li>

                                <li class="duration"
                                    v-if="mediaDetailsObj && mediaDetailsObj.msDuration && mediaDetailsObj.msDuration !== ''">
                                    <div class="item-wrapper">
                                        <div class="icon-wrapper">
                                            <img src="@/assets/svgs/duration-blue.svg" class="duration-icon" />
                                        </div>
                                        <span class="label-wrapper">{{ formatToClockDuration(mediaDetailsObj.msDuration)
                                        }}</span>
                                    </div>
                                </li>

                                <li v-if="data.fields.level &&
                                    data.fields.level.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.level" :key="item.value">
                                        <CatAsIcon :catValue="getEnumVal(item.value)" type="level" />

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>

                                    </div>
                                </li>

                                <li class="trainer-wrapper" v-if="data.fields && data.fields.coachestrainers &&
                                    data.fields.coachestrainers.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.coachestrainers"
                                        :key="item.value">
                                        <div class="icon-wrapper">
                                            <img src="@/assets/svgs/trainer.svg" class="duration-icon" />
                                        </div>
                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>

                                    </div>
                                </li>
                            </ul>
                        </div> -->
                        <div class="inner-col trainer" v-if="trainerData && trainerData !== ''">
                            <router-link :to="'/coaches-trainers/' + trainerData.slug">
                                <div class="left-side"
                                    v-if="trainerData.templateContentMedia && trainerData.templateContentMedia.overviewImage && trainerData.templateContentMedia.overviewImage.url">
                                    <div class="bg-wrapper" v-bind:style="{
        'background-image':
            'url(' + trainerData.templateContentMedia.overviewImage.url + ')',
    }"></div>
                                </div>
                                <div class="right-side"
                                    v-if="trainerData.templateContentFields && trainerData.templateContentFields.displayTitle">

                                    <div class="label">Trainer / coach</div>
                                    <div class="value">{{ trainerData.templateContentFields.displayTitle }}</div>

                                </div>
                            </router-link>
                        </div>
                        <div class="favorites-btn-wrapper" v-if="data">
                            <AddMediaToUsersList :video-id="data?.id" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- <div class="related-wrapper" v-if="data && data.relatedContentsExpanded && data.relatedContentsExpanded.length > 0">
        Related stuff
    </div> -->
    <section class="section related" v-if="playlistItems && playlistItems.length > 0">
        <div class="container">
            <h2 class="section-title">Related</h2>
            <div class="column-wrapper">
                <VideoSliderBar :data="playlistItems" />
            </div>
        </div>
    </section>

    <transition name="fade">
        <Popup :elemVisible="showTrailerVideo" :elemClass="'mini-popup'" @hidePopupEl="hidePopups()">
            <div class="column video-column">
                <div class="big-wrapper">
                    <button class="close-btn" @click="hidePopups()"></button>
                    <div :id="'popup-trailer-video-' + data.mediaId"
                        style="position: absolute; width: 100%; height: 100%">
                    </div>
                </div>
            </div>
        </Popup>
    </transition>

    <transition name="fade">
        <Popup :elemVisible="showMainVideo" :elemClass="'big-popup'" @hidePopupEl="hidePopups()">
            <div class="column video-column">
                <div class="big-wrapper">
                    <button class="back-button" @click="hidePopups()">
                        Go back
                    </button>
                    <div :id="'popup-main-video-' + data.mediaId" style="position: absolute; width: 100%; height: 100%">
                    </div>
                </div>
            </div>
        </Popup>
    </transition>
</template>

<script>
import { mapState } from "vuex";

import {
    fetchVideoContentBySlugDetails,
    fetchVideoContentVideoType,
    fetchVideoAsset,
    fetchTemplateContentList
} from "@streampac.io/chef_sp_1";

// @import component
import HeroVideoSingleContained from "@/components/HeroVideoSingleContained";

import HeroVideoSingle from "@/components/HeroVideoSingle";
import VideoSliderBar from "@/components/VideoSliderBar";

import AddMediaToUsersList from '@/components/AddMediaToUsersList';

// import CatAsIcon from "@/components/CatAsIcon";

import Popup from "@/components/Popup";

export default {
    inject: ["isAuthenticated"],
    data() {
        return {
            data: null,
            mediaDetailsObj: null,
            shortData: null,
            slug: this.$route.params.slug,
            videos: null,
            getRelatedPlaylist: "",
            storeLoggedInCheck: this.$store.state.isAuthenticated,
            playlistItems: [],
            showTrailerVideo: false,
            showMainVideo: false,
            mainVideoType: '',
            trainerData: null,
            linkedTrainer: null,
            displayNewLabel: false,
        };
    },
    components: {
        HeroVideoSingle,
        HeroVideoSingleContained,
        VideoSliderBar,
        Popup,
        AddMediaToUsersList
        // CatAsIcon
    },
    created() {
        this.fetchData();
        this.checkIVidNeedsPlayback();
    },
    computed: mapState(["storeIsAuthenticated"]),
    watch: {
        storeIsAuthenticated(val) {
            if (val == true) {
                this.fetchData();
            }
        },
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        hidePopups() {
            this.showMainVideo = false;
            this.showTrailerVideo = false;
        },
        playTrailerVid() {
            this.showTrailerVideo = true;
            this.initTrailerPlayer();
        },
        playMainVid() {
            this.showMainVideo = true;
            this.initMainPlayer();
        },
        initMainPlayer() {
            const { kWidget } = window;

            if (this.data && this.data.allowedToPlay) {
                setTimeout(() => {
                    kWidget.embed({
                        targetId: "popup-main-video-" + this.data.mediaId,
                        wid: "_" + this.$store.state.getVixyParams.partner,
                        uiconf_id: this.$store.state.getVixyParams.uiconfid,
                        flashvars: {
                            ks: this.data.kalturaSession,
                            playlistAPI: {
                                autoPlay: true,
                            },
                        },
                        entry_id: this.data.mediaId,
                    });
                }, 200);
            }
        },
        initTrailerPlayer() {
            const { kWidget } = window;
            if (this.data) {
                setTimeout(() => {
                    kWidget.embed({
                        targetId: "popup-trailer-video-" + this.data.mediaId,
                        wid: "_" + this.$store.state.getVixyParams.partner,
                        uiconf_id: this.$store.state.getVixyParams.uiconfid,
                        flashvars: {
                            ks: this.trailerData.kalturaSession,
                            playlistAPI: {
                                autoPlay: true,
                            },
                        },
                        entry_id: this.data.mediaTrailerId,
                    });
                }, 200);
            }
        },
        fetchData() {
            fetchVideoContentBySlugDetails(
                this.isAuthenticated,
                this.$route.params.slug,
                "?flattenFields=true"
            ).then((response) => {
                this.data = response;

                this.handleRelatedContent();

                //Fetch type and trainer
                this.fetchTypeAndTrainer();

                //console.log(response);

                if (this.DoesObjHasEnum(response.fields, 'Categories', 'New')) {
                    this.displayNewLabel = true;
                }

                // if( response.fields && response.fields !== '' ){
                //     this.displayNewLabel = true;
                // }

                setTimeout(() => {
                    //Fetch short
                    if (this.data && this.data.mediaShortId !== "") {
                        fetchVideoContentVideoType(
                            this.isAuthenticated,
                            this.data.id,
                            "short?validSeconds=1000"
                        ).then((response) => {
                            this.shortData = response;
                        });
                    }

                    // //Fetch Trailer
                    // if (this.data && this.data.mediaTrailerId !== "") {
                    //     fetchVideoContentVideoType(
                    //         this.isAuthenticated,
                    //         this.data.id,
                    //         "trailer?validSeconds=1000"
                    //     ).then((response) => {
                    //         this.trailerData = response;
                    //     });
                    // }

                    //Fetch video asset data
                    if (this.data.mediaId && this.data.mediaId !== "") {
                        fetchVideoAsset(
                            this.isAuthenticated,
                            this.data.mediaId
                        ).then((response) => {
                            this.mediaDetailsObj = response;
                        });
                    }
                }, 50);
            });
        },
        checkIVidNeedsPlayback() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (urlParams && urlParams !== "") {
                const playParam = urlParams.get("play");

                if (playParam && playParam == "true") {
                    setTimeout(() => {
                        this.playMainVid();
                    }, 400);
                }
            }
        },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, 0);
        },
        formatToClockDuration(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;

            minutes = seconds >= 30 ? minutes + 1 : minutes;

            minutes = minutes % 60;

            hours = hours % 24;

            if (hours > 0) {
                return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
            } else {
                return `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
            }
        },
        handleRelatedContent() {
            if (this.data && this.data.relatedContentsExpanded && this.data.relatedContentsExpanded.length > 0) {

                let adjustedArray = [];

                this.data.relatedContentsExpanded.forEach(function (item) {

                    if (item.entityType === "VideoContent") {

                        if (item.videoContent) {
                            adjustedArray.push(item.videoContent);
                        }

                    } else if (item.entityType === "TemplateContent") {
                        adjustedArray.push(item.templateContent.templateContentFields);
                    }

                });

                this.playlistItems = adjustedArray;

                this.showPlaylist = true;

            }
        },
        fetchTypeAndTrainer() {
            if (this.data && this.data.fields && this.data.fields.videotype && this.data.fields.videotype.length > 0) {
                this.mainVideoType = this.getEnumVal(this.data.fields.videotype[0].value);
            }

            if (this.data && this.data.fields && this.data.fields.coachestrainers && this.data.fields.coachestrainers.length > 0) {
                this.linkedTrainer = this.data.fields.coachestrainers[0].value;

                const fetchDataParams = {
                    filter: {
                        searchTerm: this.linkedTrainer,
                    },
                    paging: {
                        pageNumber: 1,
                        pageSize: 10,
                    },
                    flattenFields: true,
                };

                const getContentTypes = this.$store.state.getContentTypes;

                if (getContentTypes) {
                    const chosenType = getContentTypes.trainersAndCoaches;

                    fetchTemplateContentList(
                        this.isAuthenticated,
                        chosenType,
                        fetchDataParams,
                        "?flattenFields=true"
                    ).then((response) => {
                        this.trainerData = response[0];

                        //console.log(this.trainerData);
                    });

                }
            }
        },
        DoesObjHasEnum(fieldsObj, definedEnum, checkForEnumName) {
            if (fieldsObj && fieldsObj !== '' && fieldsObj[definedEnum] && fieldsObj[definedEnum].length > 0) {

                let enumNames = [];

                if (fieldsObj[definedEnum] && fieldsObj[definedEnum].length > 0) {

                    fieldsObj[definedEnum].forEach((singleEnum) => {
                        const enumAsName = this.getEnumVal(singleEnum.value);

                        enumNames.push(enumAsName);
                    });

                    if (enumNames.includes(checkForEnumName)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        checkIfHasDownloads() {
            if (this.data && this.data.fields) {
                if (
                    this.data.fields.nlinstructions && this.data.fields.nlinstructions !== '' ||
                    this.data.fields.eninstructions && this.data.fields.eninstructions !== '' ||
                    this.data.fields.frinstructions && this.data.fields.frinstructions !== '' ||
                    this.data.fields.deinstructions && this.data.fields.deinstructions !== '' ||
                    this.data.fields.esinstructions && this.data.fields.esinstructions !== ''
                ) {
                    return true;
                }
                return false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
.column.desc * {
    line-height: 1.5;
}
</style>
