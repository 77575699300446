<template>
    <transition name="fade">
        <div class="add-to-list-wrapper" v-if="isAuthenticated && videoId">
            <!-- <transition name="fade">
                <span class="notice" v-if="displayNotice">{{ noticeText }}</span>
            </transition> -->
            <button v-if="showBtn" class="add-to-users-list button" :class="{ loading: displayNotice }"
                @click="handleAddOrRemoval">
                <span v-if="!videoInUserFavorites"><img src="@/assets/svgs/heart-outline.svg">Add to favourites</span>
                <span v-else-if="videoInUserFavorites"><img src="@/assets/svgs/heart-filled.svg">Remove from
                    favourites</span>
            </button>
        </div>
    </transition>
    <transition name="fade">
        <router-link v-if="showBtn && showAllBtn" class="show-fav" to="/my-favourites">Show favourites</router-link>
    </transition>
</template>

<script>

//fetchUserFavoriteVideos

//removeVideoFromUserFavorites
// import { addVideoToUserFavorites } from '@streampac.io/chef_sp_1';

import { fetchUserFavoriteVideos, addVideoToUserFavorites, removeVideoFromUserFavorites } from '@streampac.io/chef_sp_1';

export default {
    components: {},
    data() {
        return {
            //data: null,
            displayNotice: false,
            noticeText: '',
            videoInUserFavorites: false,
            showBtn: false,
            relatedFavoriteId: null,
            showAllBtn: false,
            // mediaId: ''
        };
    },
    inject: ['isAuthenticated'],
    props: ['videoId'],
    mounted() {

        this.checkIfisFavorite();

        setTimeout(() => {
            this.showBtn = true;
        }, 450);
    },
    methods: {
        checkIfisFavorite() {
            setTimeout(() => {
                const getUserFavoriteVideos = this.$store.state.getUserFavoriteVideos;


                if (getUserFavoriteVideos && getUserFavoriteVideos.length > 0) {

                    const checkFavorites = getUserFavoriteVideos.filter(
                        item =>
                            item.videoContentId == this.videoId
                    );

                    if (checkFavorites && checkFavorites.length > 0) {
                        this.videoInUserFavorites = true;
                        this.relatedFavoriteId = checkFavorites[0].id;
                        this.showAllBtn = true;
                    }
                }
            }, 400);

        },
        handleAddOrRemoval() {
            if (this.videoId) {
                const getUserProfile = this.$store.state.getUserProfile;

                if (!this.videoInUserFavorites) {
                    addVideoToUserFavorites(this.isAuthenticated, this.videoId, getUserProfile.userId).then(response => {
                        if (response) {
                            this.displayNotice = true;
                            // this.noticeText = 'Toegevoegd aan je lijst!';
                            //this.videoInUserFavorites = true;

                            this.saveUserFavorites();

                            setTimeout(() => {
                                this.videoInUserFavorites = true;
                                this.showAllBtn = true;
                            }, 1000);
                        } else {
                            this.displayNotice = true;
                            this.noticeText = 'Er ging iets mis..';
                        }


                    });
                } else {
                    //console.log('remove from favorites');
                    removeVideoFromUserFavorites(this.isAuthenticated, this.relatedFavoriteId).then(response => {
                        if (response) {
                            this.displayNotice = true;
                            //this.noticeText = 'Verwijderd uit je lijst';
                            // this.videoInUserFavorites = false;

                            setTimeout(() => {
                                this.videoInUserFavorites = false;
                                this.showAllBtn = false;
                            }, 1000);

                            this.saveUserFavorites();
                        } else {
                            this.displayNotice = true;
                            this.noticeText = 'Er ging iets mis..';
                        }
                    });
                }

                setTimeout(() => {
                    this.displayNotice = false;
                    // this.noticeText = '';
                    this.$store.commit("triggerFavoriteFetch", true);
                }, 2000);

            }
        },
        saveUserFavorites() {
            const getUserProfile = this.$store.state.getUserProfile;
            if (this.isAuthenticated && getUserProfile) {

                const params = {
                    "userId": getUserProfile.userId,
                    "flattenFields": false,
                    "getUnpublishVideosToo": false
                };

                fetchUserFavoriteVideos(this.isAuthenticated, params).then(response => {
                    if (response) {
                        this.$store.commit("saveUserFavoriteVideos", response);
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
// @import styles
@import './index.scss';
</style>
