<template>
    <section class="hero video" v-if="data && trainerData">
        <!-- <div class="bg-vid-wrapper" v-if="data.mediaShortId">
			<div
				:id="'hero-' + data.mediaShortId"
				style="position: absolute; width: 100%; height: 100%"
			></div>
		</div>
		<div
			class="bg-img-wrapper"
			v-else-if="!data.mediaShortId && data.detailImageUrl"
			v-bind:style="{ 'background-image': 'url(' + data.detailImageUrl + ')' }"
		></div> -->
        <div class="top-meta-bar">
            <div class="container">
                <button class="back-button" @click="
                    hasHistory() ? $router.go(-1) : $router.push('/')
                    ">
                    Go back
                </button>
            </div>
        </div>
        <div class="bg-vid-wrapper">

            <div class="bg-vid-inner-container" v-if="shortData">
                <div :id="'hero-' + shortData.mediaId" style="position: absolute; width: 100%; height: 100%"></div>
            </div>
            <transition name="vid-fade">
                <div class="thumbnail" v-if="!videoStarted && trainerHeroImage && trainerHeroImage !== ''">
                    <div class="bg-wrapper" v-if="trainerHeroImage.url" v-bind:style="{
                        'background-image':
                            'url(' + trainerHeroImage.url + ')',
                    }"></div>
                </div>
            </transition>
            <transition name="vid-fade">
                <div class="thumbnail" v-if="!videoStarted && data.detailImage">
                    <div class="bg-wrapper" v-if="data.detailImage.url" v-bind:style="{
                        'background-image':
                            'url(' + data.detailImage.url + ')',
                    }"></div>
                </div>
            </transition>
            <transition name="vid-fade">
                <div class="thumbnail" v-if="!data.mediaShortId && data.detailImageUrl">
                    <div class="bg-wrapper" v-if="data.detailImageUrl" v-bind:style="{
                        'background-image':
                            'url(' + data.detailImageUrl + ')',
                    }"></div>
                </div>
            </transition>
        </div>
        <div class="container">
            <div class="column-wrapper">
                <div class="column intro">
                    <div class="video-meta">

                        <h1 class="trainer-name" v-if="data.fields && data.fields.coachestrainers &&
                            data.fields.coachestrainers.length > 0
                            ">
                            {{ getEnumVal(data.fields.coachestrainers[0].value) }}

                        </h1>

                        <p class="video-title" v-if="data.title">
                            {{ data.title }}
                        </p>
                        <div class="video-meta-inner" v-if="data.fields && data.fields.length > 0">
                            <ul class="meta-list">
                                <!-- <li class="teacher">{{ getEnumVal() }}</li> -->
                                <li v-if="data.freeVideo" class="special-label">
                                    Free
                                </li>
                                <li class="teacher" v-if="data.fields.teachers.length > 0">
                                    <ul>
                                        <li v-for="val in data.fields.teachers" :key="val">
                                            {{ getEnumVal(val.value) }}
                                        </li>
                                    </ul>
                                </li>
                                <li class="teacher" v-if="data.fields.yogastyles.length > 0">
                                    <ul>
                                        <li v-for="val in data.fields
                                            .yogastyles" :key="val">
                                            {{ getEnumVal(val.value) }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="video-short-desc" v-if="data.shortDescription" v-html="data.shortDescription"></div>
                        <ul class="meta-bar">
                            <!-- <li
								v-if="
									isAuthenticated &&
										!hasNonFreeSubscription() &&
										!data.allowedToPlay
								"
							>
								<button @click="rentVid()" class="button white-btn play">
									Huur voor..
								</button>
							</li> -->
                            <!-- <li v-if="isAuthenticated && hasNonFreeSubscription()"></li> -->
                            <li v-if="data.allowedToPlay">
                                <button @click="playMainVideo()" class="button play">
                                    Play
                                </button>
                            </li>
                            <!-- <li v-if="isAuthenticated"> -->
                            <li v-if="data.mediaTrailerId &&
                                data.mediaTrailerId !== ''
                                ">
                                <button data-txt="Watch Trailer" @click="playTrailerVideo()" class="button outline-btn">
                                    Watch Trailer
                                </button>
                            </li>
                            <!-- <li v-if="!hasNonFreeSubscription()">
								<router-link to="/abonneer-nu" class="button white-btn play">
									Abonneer op Dalton
								</router-link>
							</li> -->
                            <li v-if="!isAuthenticated && !data.allowedToPlay">
                                <button data-txt="Login" @click="loginPopup()" class="button outline-btn">
                                    Login
                                </button>
                            </li>
                            <li v-if="isAuthenticated && !hasNonFreeSubscription()
                                ">
                                <router-link to="/pricing" data-txt="Choose a plan" class="button">
                                    Choose a plan
                                </router-link>
                            </li>
                            <!-- <li
								v-if="
									isAuthenticated && hasNonFreeSubscription()
								"
							>
								<router-link
									to="/pricing"
									data-txt="pay per view"
									class="button"
								>
									pay per view
								</router-link>
							</li> -->
                            <!-- <li>
								<AddMediaToUsersList />
							</li> -->
                        </ul>

                        <!-- Valid sub checken bij te bekijken tot -->
                        <!-- <div v-if="data.allowedToPlay && isAuthenticated" class="bar-info">
							Te bekijken tot:
							{{ fullDateFormatted(data.allowedToPlayUntil) }}
						</div>
						<div
							v-else-if="!data.allowedToPlay && !hasNonFreeSubscription()"
						>
							Huur de film, je krijgt toegang voor 48 uur.
						</div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { checkoutPayperview } from "@streampac.io/chef_sp_1";

// import { fetchVideoContentVideoType } from "@streampac.io/chef_sp_1";

//import AddMediaToUsersList from "@/components/AddMediaToUsersList";

export default {
    name: "HeroVideoSingle",
    components: {
        //AddMediaToUsersList,
    },
    data() {
        return {
            getUserData: this.$store.state.getUserData,
            videoStarted: false,
            trainerHeroImage: null,
        };
    },
    inject: ["isAuthenticated"],
    emits: ["playMainVid", "playTrailerVid"],
    props: ["shortData", "data", "videoAssetData", "trainerData"],
    created() {
        // setTimeout(() => {
        // 	this.transformDataValues();
        // }, 400);

        setTimeout(() => {
            this.initPlayer();
        }, 700);

        let $i = 0;

        const GetUserDataFromStore = setInterval(() => {
            if (
                this.$store.state.getUserData &&
                this.$store.state.getUserData != null
            ) {
                this.getUserData = this.$store.state.getUserData;
                clearInterval(GetUserDataFromStore);
            }

            if ($i > 10) {
                clearInterval(GetUserDataFromStore);
            }

            $i++;
        }, 50);

        setTimeout(() => {
            //this.fetchDetails();
        }, 300);
    },
    watch: {
        trainerData() {
            this.handleTrainerData();
        }
    },
    computed: {
        dateFormatted() {
            let initialDate = this.data.date;

            let d = new Date(initialDate);

            const year = d.getFullYear();

            return year;
        },
    },
    methods: {
        fullDateFormatted(date) {
            let initialDate = date;

            //Timezone offset
            let hrs = -(new Date().getTimezoneOffset() / 60);

            const months = [
                "Jan.",
                "Feb.",
                "Mrt.",
                "Apr.",
                "Mei.",
                "Jun.",
                "Jul.",
                "Aug.",
                "Sept.",
                "Okt.",
                "Nov.",
                "Dec.",
            ];

            let d = new Date(initialDate);

            const addZero = (i) => {
                if (i < 10) {
                    i = "0" + i;
                }
                return i;
            };

            const monthIndex = d.getMonth();
            const monthName = months[monthIndex];
            const year = d.getFullYear();
            const day = d.getDate();
            const hours = addZero(d.getHours() + hrs);
            const minutes = addZero(d.getMinutes());

            return (
                day + " " + monthName + " " + year + " " + hours + ":" + minutes
            );
        },
        hasHistory() {
            return window.history.length > 2;
        },
        hasNonFreeSubscription() {
            if (
                this.getUserData &&
                this.getUserData.subscription != "" &&
                this.getUserData.subscription != "Free"
            ) {
                return true;
            }

            return false;
        },
        fetchDetails() { },
        initPlayer() {
            const { kWidget } = window;

            if (this.data && this.shortData) {
                kWidget.embed({
                    targetId: "hero-" + this.data.mediaShortId,
                    wid: "_" + this.$store.state.getVixyParams.partner,
                    uiconf_id: this.$store.state.getVixyParams.uiconfid,
                    flashvars: {
                        ks: this.shortData.kalturaSession,
                        loop: true,
                        autoMute: true,
                        playlistAPI: {
                            autoPlay: true,
                        },
                        //Remove controls
                        "controlBarContainer.plugin": false,
                        "largePlayBtn.plugin": false,
                        "loadingSpinner.plugin": false,
                    },
                    entry_id: this.data.mediaShortId,
                    readyCallback: () => {
                        this.videoStarted = true;
                    },
                });
            }
        },
        // padTo2Digits(num) {
        // 	return num.toString().padStart(2);
        // },
        // getHoursAndMinutes(milliseconds) {
        // 	let seconds = Math.floor(milliseconds / 1000);
        // 	let minutes = Math.floor(seconds / 60);
        // 	let hours = Math.floor(minutes / 60);

        // 	seconds = seconds % 60;

        // 	minutes = seconds >= 30 ? minutes + 1 : minutes;

        // 	minutes = minutes % 60;

        // 	hours = hours % 24;

        // 	if (hours > 0) {
        // 		return `${this.padTo2Digits(hours)}u ${this.padTo2Digits(minutes)}m`;
        // 	} else {
        // 		return `${this.padTo2Digits(minutes)}m`;
        // 	}
        // },
        playMainVideo() {
            this.$emit("playMainVid", true);
        },
        playTrailerVideo() {
            this.$emit("playTrailerVid", true);
        },
        rentVid() {
            const postObj = {
                mediaId: this.data.mediaId,
                paymentSuccessUrl: window.location.pathname,
            };

            checkoutPayperview(this.isAuthenticated, postObj).then(
                (response) => {
                    if (response.succes) {
                        window.location.replace(response.redirectUrl);
                    }
                }
            );
        },
        transformDataValues() {
            if (this.data && this.data.fields) {
                const fields = this.data.fields;

                if (fields.genre && fields.genre.length > 0) {
                    this.lookupDataFieldValues("genre", fields.genre);
                }
            }
        },
        lookupDataFieldValues(type, targetFields) {
            setTimeout(() => {
                const enums = this.$store.state.getEnumLookup;

                let newArray = [];

                if (type == "genre") {
                    targetFields.forEach((item) => {
                        const filteredValues = enums.filter(function (enumItem) {
                            if (enumItem.id === item.value) {
                                return enumItem;
                            }
                        });

                        newArray.push(filteredValues[0].enumValue);
                    });

                    this.metaFields.genre = newArray;
                }
            }, 50);
        },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        getFormatDate(val) {
            let d = new Date(val);

            const monthIndex = d.getMonth() + 1;
            const year = d.getFullYear();
            const day = d.getDate();

            return day + "-" + monthIndex + "-" + year;
        },
        loginPopup() {
            this.$store.commit("toggleLoginPopup");
        },
        handleTrainerData() {
            if (this.trainerData && this.trainerData.templateContentMedia && this.trainerData.templateContentMedia.headerImage && this.trainerData.templateContentMedia.headerImage !== '') {
                this.trainerHeroImage = this.trainerData.templateContentMedia.headerImage;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
@media only screen and (max-width: 500px) {
    .hero.video {
        .bg-vid-wrapper {
            iframe {
                transform: scale(1.5);
            }
        }
    }
}
</style>
