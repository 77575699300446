<template>
    <section class="hero video" v-if="data && videoAssetData">
        <div class="banner">
            <div class="container">
                <div class="top-meta-bar">
                    <button class="back-button" @click="checkBack()">Go back</button>
                </div>
                <div class="hero-meta">
                    <div class="column-wrapper">
                        <div class="column info">
                            <div class="title-wrapper">
                                <h1 class="page-title">{{ data.title }}</h1>
                                <span v-if="displayNewLabel" class="global-label new">
                                    New
                                </span>
                                <span class="global-label free" v-if="data.freeVideo">
                                    Free
                                </span>
                            </div>
                            <div class="video-short-desc" v-if="this.data.shortDescription"
                                v-html="this.data.shortDescription"></div>
                        </div>
                        <div class="column meta-wrapper" v-if="data.fields">
                            <ul class="labels">
                                <li v-if="data.fields.skills &&
                                    data.fields.skills.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.skills" :key="item.value">
                                        <CatAsIcon :catValue="getEnumVal(item.value)" type="skill" />

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>

                                    </div>
                                </li>

                                <li class="duration"
                                    v-if="videoAssetData && videoAssetData.msDuration && videoAssetData.msDuration !== ''">
                                    <div class="item-wrapper">
                                        <div class="icon-wrapper">
                                            <img src="@/assets/svgs/duration-blue.svg" class="duration-icon" />
                                        </div>
                                        <span class="label-wrapper">{{ formatToClockDuration(videoAssetData.msDuration)
                                        }}</span>
                                    </div>
                                </li>

                                <li v-if="data.fields.level &&
                                    data.fields.level.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.level" :key="item.value">
                                        <CatAsIcon :catValue="getEnumVal(item.value)" type="level" />

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>

                                    </div>
                                </li>

                                <li class="trainer-wrapper" v-if="data.fields && data.fields.coachestrainers &&
                                    data.fields.coachestrainers.length > 0
                                    ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.coachestrainers"
                                        :key="item.value">
                                        <div class="icon-wrapper">
                                            <img src="@/assets/svgs/trainer.svg" class="duration-icon" />
                                        </div>
                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-vid-wrapper">
            <div class="container">
                <transition name="vid-fade">
                    <button @click="initPlayer()" v-if="!videoStarted" class="button white-btn play">
                        Play
                    </button>
                </transition>

                <div class="bg-vid-inner-container">
                    <div :id="'hero-' + data.mediaShortId" style="position: absolute; width: 100%; height: 100%"></div>
                </div>

                <transition name="vid-fade">
                    <div class="thumbnail" v-if="!videoStarted">
                        <div class="bg-wrapper" v-if="data.overviewImageUrl" v-bind:style="{
                            'background-image':
                                'url(' + data.overviewImageUrl + ')',
                        }"></div>
                        <div class="bg-wrapper" v-else-if="videoAssetData && videoAssetData.thumbnailUrl !== ''
                            " v-bind:style="{ 'background-image': 'url(' + getVixyThumbnailUrl() + ')' }"></div>
                    </div>
                </transition>
                <div class="column video-column">
                    <div class="big-wrapper">
                        <div :id="'main-video-' + data.mediaId" style="position: absolute; width: 100%; height: 100%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { checkoutPayperview } from "@streampac.io/chef_sp_1";

// import { fetchVideoContentVideoType } from "@streampac.io/chef_sp_1";

// import AddMediaToUsersList from "@/components/AddMediaToUsersList";
import CatAsIcon from "@/components/CatAsIcon";

export default {
    name: "HeroVideoSingleContained",
    components: {
        // AddMediaToUsersList,
        CatAsIcon,
    },
    data() {
        return {
            getUserData: this.$store.state.getUserData,
            AdjustedDesc: "",
            videoStarted: false,
            displayNewLabel: false,
        };
    },
    inject: ["isAuthenticated"],
    //emits: ["playMainVid", "playTrailerVid"],
    props: ["shortData", "data", "videoAssetData"],
    beforeMount() { },
    created() {
        setTimeout(() => {
            if (this.data && this.DoesObjHasEnum(this.data.fields, 'Categories', 'New')) {
                this.displayNewLabel = true;
            }

            if (this.data && this.data.shortDescription !== "") {
                let replacedTxt = this.data.shortDescription.replace(
                    /(<([^>]+)>)/gi,
                    ""
                );
                this.AdjustedDesc = replacedTxt.substr(0, 194) + "...";
            }

        }, 150);



        setTimeout(() => {
            this.transformDataValues();
        }, 300);

        let $i = 0;

        const GetUserDataFromStore = setInterval(() => {
            if (
                this.$store.state.getUserData &&
                this.$store.state.getUserData != null
            ) {
                this.getUserData = this.$store.state.getUserData;
                clearInterval(GetUserDataFromStore);
            }

            if ($i > 10) {
                clearInterval(GetUserDataFromStore);
            }

            $i++;
        }, 50);

        this.checkIVidNeedsPlayback();
    },
    computed: {
        dateFormatted() {
            let initialDate = this.data.date;

            let d = new Date(initialDate);

            const year = d.getFullYear();

            return year;
        },
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        userHasCorrectSubscription() {
            if (
                this.getUserData &&
                this.getUserData.subscription != "" &&
                this.getUserData.subscription != "Free"
            ) {
                return true;
            }

            return false;
        },
        initPlayer() {
            const { kWidget } = window;

            if (this.data && this.data.kalturaSession != "") {
                kWidget.embed({
                    targetId: "main-video-" + this.data.mediaId,
                    wid: "_" + this.$store.state.getVixyParams.partner,
                    uiconf_id: this.$store.state.getVixyParams.uiconfid,
                    flashvars: {
                        ks: this.data.kalturaSession,
                        playlistAPI: {
                            autoPlay: false,
                            autoMute: false,
                        },
                        "controlBarContainer.plugin": true,
                        "largePlayBtn.plugin": true,
                        "loadingSpinner.plugin": true,
                    },
                    entry_id: this.data.mediaId,

                    readyCallback: () => {
                        this.videoStarted = true;

                        //Start the video with sound. Force "autoPlay" this way.
                        var kdp = document.getElementById("main-video-" + this.data.mediaId);

                        kdp.kBind('mediaReady', function () {
                            kdp.sendNotification('doPlay');
                        })
                    },
                });
            }
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, 0);
        },
        formatToClockDuration(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;

            minutes = seconds >= 30 ? minutes + 1 : minutes;

            minutes = minutes % 60;

            hours = hours % 24;

            if (hours > 0) {
                return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
            } else {
                return `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
            }
        },
        // initMainPlayer() {
        //     const { kWidget } = window;

        //     setTimeout(() => {
        //         kWidget.embed({
        //             targetId: "popup-main-video-" + this.data.mediaId,
        //             wid: "_391",
        //             uiconf_id: 23452148,
        //             flashvars: {
        //                 ks: this.data.kalturaSession,
        //                 playlistAPI: {
        //                     autoPlay: true,
        //                 },
        //             },
        //             entry_id: this.data.mediaId,
        //         });
        //     }, 200);
        // },
        // playTrailerVideo() {
        //     this.$emit("playTrailerVid", true);
        // },
        rentVid() {
            const postObj = {
                mediaId: this.data.mediaId,
                paymentSuccessUrl: window.location.pathname,
            };

            checkoutPayperview(this.isAuthenticated, postObj).then(
                (response) => {
                    if (response.succes) {
                        window.location.replace(response.redirectUrl);
                    }
                }
            );
        },
        transformDataValues() {
            if (this.data && this.data.fields) {
                const fields = this.data.fields;

                if (fields.genre && fields.genre.length > 0) {
                    this.lookupDataFieldValues("genre", fields.genre);
                }
                if (fields.rating && fields.genre.length > 0) {
                    this.lookupDataFieldValues("kijkwijzer", fields.rating);
                }
            }
        },
        lookupDataFieldValues(type, targetFields) {
            setTimeout(() => {
                const enums = this.$store.state.getEnumLookup;

                let newArray = [];

                if (type == "genre") {
                    targetFields.forEach((item) => {
                        const filteredValues = enums.filter(function (enumItem) {
                            if (enumItem.id === item.value) {
                                return enumItem;
                            }
                        });

                        newArray.push(filteredValues[0].enumValue);
                    });

                    this.metaFields.genre = newArray;
                }

                if (type == "kijkwijzer") {
                    targetFields.forEach((item) => {
                        const filteredValues = enums.filter(function (enumItem) {
                            if (enumItem.id === item.value) {
                                return enumItem;
                            }
                        });

                        newArray.push(filteredValues[0].enumValue);
                    });

                    this.metaFields.kijkwijzer = newArray;
                }
            }, 50);
        },
        checkBack() {
            if (this.hasHistory()) {
                // let queryParams = {};

                // if (this.$route.meta.graden && this.$route.meta.graden !== "") {
                //     queryParams["graden"] = this.$route.meta.graden;
                // }

                // if (this.$route.meta.thema && this.$route.meta.thema !== "") {
                //     queryParams["thema"] = this.$route.meta.thema;
                // }

                // this.$router.push({
                //     path: "/films",
                //     query: queryParams,
                // });

                this.$router.go(-1);
            } else {
                this.$router.push("/videos");
            }
        },
        getVixyThumbnailUrl() {
            if (this.videoAssetData && this.videoAssetData !== "") {
                const originalStr = this.videoAssetData.thumbnailUrl;

                //this.videoAssetData

                //const newStr = originalStr.replace("/width/200", "/width/600");
                const newStr = originalStr.replace(
                    "/width/200",
                    "/width/2560/height/1440/type/4/"
                );

                return newStr;
            }
        },
        checkIVidNeedsPlayback() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (urlParams && urlParams !== "") {
                const playParam = urlParams.get("play");

                if (playParam && playParam == "true") {
                    setTimeout(() => {
                        this.initPlayer();
                    }, 400);
                }
            }
        },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        DoesObjHasEnum(fieldsObj, definedEnum, checkForEnumName) {
            if (fieldsObj && fieldsObj !== '' && fieldsObj[definedEnum] && fieldsObj[definedEnum].length > 0) {

                let enumNames = [];

                if (fieldsObj[definedEnum] && fieldsObj[definedEnum].length > 0) {

                    fieldsObj[definedEnum].forEach((singleEnum) => {
                        const enumAsName = this.getEnumVal(singleEnum.value);

                        enumNames.push(enumAsName);
                    });

                    if (enumNames.includes(checkForEnumName)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
.hero.video {
    .video-short-desc {
        * {
            //font-weight: $mediumWeight;
            line-height: 24px;
            color: #FFF;
        }
    }
}
</style>
